


















import { Vue, Component } from "vue-property-decorator";
import companyEnquiryOverviewRoutes from "@/router/companyEnquiryOverview";

@Component
export default class Index extends Vue {
  private routes = companyEnquiryOverviewRoutes;
}
